import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const NewLetter = () => {
  return (
    <Container>
        <div className='nlContainer'>
            <p>Donate us to fight against human trafficking</p>
            <button><Link to='/donate'>DONATE</Link></button>
        </div>
    </Container>
  )
}

export default NewLetter

const Container = styled.div`
    width: 100%;
    background-color: #FDCB4A;
    position: fixed;
    top: 0;
    padding: .2rem 0;
    z-index: 99999;
    /* max-width: 1300px; */
    /* margin: 0 auto; */
    .nlContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        p{
            font-size: clamp(.6rem, 2vw, 1rem);

        }
        button{
            border: none;
            font-size: clamp(.4rem, 2vw, .8rem);
            margin: 0 1rem;
            padding: 0.2rem 0.5rem;
            border-radius: 9px;
            font-family: skb;
            
            a{
                
                color: black;
            }
            
        }
    }
`