import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Reports = () => {
  return (
    <Container>

      <Cards>

        <Card target='blank' href="./pdf/Annual Report - 2023.pdf" >

          <div className='imgContainer' >
            <img className='image' src="./images/reports/6.jpg" alt="" />
          </div>

          <h3>Annual Report 2023</h3>

          <div to='' className='link'>
            <a target='blank' href="./pdf/Annual Report - 2023.pdf">READ THE FULL REPORT</a>
            <div>
              <img className='arrow' src="./icons/arrow.png" alt="" />
            </div>
          </div>
          
        </Card>

          <Card target='blank' href="./pdf/CG Annual report _ 2022.pdf" >

                <div className='imgContainer' >
                  <img className='image' src="./images/reports/5.jpg" alt="" />
                </div>

                <h3>Annual Report 2022</h3>

                <div to='' className='link'>
                  <a target='blank' href="./pdf/CG Annual report _ 2022.pdf">READ THE FULL REPORT</a>
                  <div>
                    <img className='arrow' src="./icons/arrow.png" alt="" />
                  </div>
                </div>
          
        </Card>

        <Card target='blank' href="./pdf/Cross Global Annual Report - 2021.pdf" >

          <div className='imgContainer' >
            <img className='image' src="./images/reports/1.jpg" alt="" />
          </div>

          <h3>Annual Report 2021</h3>

          <div to='' className='link'>
            <a target='blank' href="./pdf/Cross Global Annual Report - 2021.pdf">READ THE FULL REPORT</a>
            <div>
              <img className='arrow' src="./icons/arrow.png" alt="" />
            </div>
          </div>
          
        </Card>

        <Card target='blank' href='./pdf/CROSS GLOBAL ASIA FOUNDATION - Financial Report FY23.pdf'>

        
          <div className='imgContainer'>
            <img className='image' src="./images/reports/4.jpg" alt="" />
          </div>

          <h3>CGAF - Audit FY - 2022</h3>

          <div className='link'>
            <a target='blank' href='./pdf/CROSS GLOBAL ASIA FOUNDATION - Financial Report FY23.pdf'>AUDIT FY - 2021</a>
            <div>
              <img className='arrow' src="./icons/arrow.png" alt="" />
            </div>
          </div>

        </Card>

      </Cards>

    </Container>
  )
}

export default Reports

const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem 0rem;
  /* margin-top: 3rem; */
  margin-bottom: 8rem;
  
`

const Cards = styled.div`
  display: flex;
  gap: 60px;
  justify-content: center;
  /* height: 70vh; */
  align-items: center;
  flex-wrap: wrap;
  
  

  @media (max-width:600px) {
    flex-direction: column;
    gap: 40px;
    padding: 2rem 2rem;
  }
  
`

const Card = styled.a`

  /* width: 50%; */
  /* overflow: hidden; */

  @media (max-width:650px) {
    border-bottom: 1px #00000053 solid;
    padding-bottom: 2rem;
  }

 
  
   

  &:hover .image{
    transform: scale(1.05);
  }

  &:hover .arrow{
    transform: translateX(10px);
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
  }


  .link{
    display: flex;
    gap: 15px;
    align-items: center;
    color: black;

    a{
      color: black;
    }

    div{
      img{
        width: 20px;
        transition: 0.3s all ease-in-out;
      }
    }
  }

  h3{
    font-size: clamp(1.5rem, 2.5vw, 4rem);
    padding: 1rem 0;
  }
  
  p{
    font-size: clamp(1rem, 1vw, 1.5rem);
  }

  .imgContainer{
    width: 100%;
    border-radius: 9px;
    overflow: hidden;
    img{
      width: 100%;
      object-fit: cover;
      transition: 0.3s all ease-in-out;
      border-radius: 9px;
    }
  }
`