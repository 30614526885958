import React, { useRef, useEffect} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const Title = () => {

  const textRef = useRef(null)

  useEffect(()=>{
    const el = textRef.current

    gsap.fromTo(el.querySelector('.title'),{opacity:0},{
      opacity:1,
      duration:2,
      scrollTrigger:{
        trigger:el,
        scrub: 2,
        // markers:true,
        start: 'top 65%',
        end:'top 60%',
      }
    })

  },[])

  return (
    <Container ref={textRef}>
        <h1 className='title'>Until All Are Free</h1>
    </Container>
  )
}

export default Title

const Container = styled.div`
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    @media (max-width:1145px) {
        display: none;
    }

    h1{
      color: white;
      position: absolute;
      top: -20%;
      z-index: 9;
      font-size: clamp(3rem, 7vw, 7rem);
      text-align: center;
    }
`