import React, { useState } from 'react';
import Mailchimp from 'react-mailchimp-subscribe';
import styled from 'styled-components';

const CustomForm = () => {
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email } = e.target.elements;
    const data = {
      email: email.value,
    };

    Mailchimp.subscribe('YOUR_API_KEY', 'af2eeb69f2', data)
      .then((response) => {
        setStatus(response.status);
      })
      .catch((error) => {
        setStatus(error.status);
      });
  };

  return (
    <Main>
        <form onSubmit={handleSubmit}>
        <input name="email" placeholder="Enter your email address" />
        <button type="submit">Subscribe</button>
        {status && <p>{status}</p>}
        </form>
    </Main>
  );
};

export default CustomForm;


const Main = styled.div`
    form{
        button{
            margin-top: 20rem;
        }
    }
`

