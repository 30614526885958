import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Route, Routes, useLocation} from 'react-router-dom'
import { ReactComponent as Facebook } from '../socials/facebook.svg'
import { ReactComponent as Instagram } from '../socials/instagram.svg'
import { ReactComponent as LinkedIn } from '../socials/linkedin.svg'
import { ReactComponent as Twitter } from '../socials/twitter.svg'

const Navbar = () => {

    const homePage = () =>{
        window.scrollTo(0,0);
    }


    const toggle = () =>{

        let btn = document.querySelector('.phonemenu')
        btn.classList.toggle('toggle-menu')

        let line = document.querySelector('.line')
        line.classList.toggle('line-one')

        let lineTwo = document.querySelector('.line2')
        lineTwo.classList.toggle('line-two')

    }

  return (
    <Container>
       
        <Phone>
            <div className='phonemenu'>
                <PhoneLinks>

                    <p className='sub'>NAVIGATION</p>

                    <Link className='link' onClick={toggle} to='/humantrafficking'>Human Trafficking</Link>
                    <Link className='link' onClick={toggle} to='/ourproject' >Our Projects</Link>
                    <Link className='link' onClick={toggle} to='/meettheteam' >Meet The Team</Link>
                    <Link className='link' onClick={toggle} to='/donate'  >Donate</Link>

                    <Button>
                        <Link onClick={toggle} to='/volunteer' className='meetTheTeam'>Volunteer</Link>
                        <Link onClick={toggle} to='/contactus'>
                            <Donate>
                                <p>Contact Us</p>
                                
                            </Donate>
                        </Link>
                        
                    </Button>

                    <Socials>
                        <p className='sub'>SOCIALS</p>
                         <div className='svg-container'>

                            <a href='https://www.instagram.com/crossglobal/?hl=en' target='_blank' className="svg-component">
                                <Instagram />
                            </a>
                            <a href='https://www.facebook.com/CrossGlobalIndia/' target='_blank' className="svg-component">
                                <Facebook />
                            </a>
                            <a href='https://twitter.com/CrossglobalInd' target='_blank' className="svg-component">
                                <Twitter />
                            </a>
                            <a href='https://www.linkedin.com/company/crossglobal/' target='_blank' className="svg-component">
                                <LinkedIn />
                            </a>
                        </div>
                    </Socials>

                    
                </PhoneLinks>
            </div>
        </Phone>

        <Nav>
            <Logo>
                <Link onClick={homePage} to="/"><img src="./images/CglogBlack.png" alt="" /></Link>
            </Logo>

           

            <Menu onClick={toggle}>
                <div className='line line1'></div>
                <div className='line line2'></div>
            </Menu>

        </Nav>

    </Container>
  )
}

export default Navbar

const Container = styled.div`
    display: flex;
    position: relative;
    z-index: 9999;

    
`

const Phone = styled.div`


    .phonemenu{
        background-color: #1E1E1E;
        
        width: 40%;
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        transform: translateX(101%);  
        transition: 0.5s all ease-in-out;
        
        @media (max-width:1070px) {
            width: 100%;
        }
    }       

    .toggle-menu{
        transform: translateX(0%);   
        filter: drop-shadow(-10px 0px 30px #00000031);
    }
`


const PhoneLinks = styled.ul`
    margin-top: 9rem;
    margin-left: 7rem;

    @media (max-width:420px) {
        margin-top: 7rem;
    }
    
    .sub{
        color: #ffffff53;
        margin-bottom: 2rem;
    }

    .link{
        font-size: 1.8rem;
        padding: .6rem 0; 
        color: white;
        display: flex;
        font-family: skr;
    }

    @media (max-width:1300px) {
        margin-left: 5rem;
    }

    @media (max-width:500px) {
        margin-left: 3rem;
    }
`

const Nav = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem;
    position: fixed;
    /* transform: translateY(-100%); */

    @media (max-width: 500px) {
        padding: 2rem;
    }
    

`

const Logo = styled.div`

    a{
      img{
        width: 86px;

        @media (max-width: 500px) {
            width: 66px;
            padding: .5rem 0;
        }
      }
    }
`


const Menu = styled.div`
    padding: 1rem;
    z-index: 2;
    transition: 0.2s all ease-in-out;


    div{
        width: 35px;
        height: 1.5px;
        background-color: #0c0c0c;
        margin: .4rem 0;
        transition: 0.2s all ease-in-out;
    }

    .line-one{
        transform: translatey(8px) rotatez(45deg);
        background-color: white;
        
    }

    .line-two{
        transform: rotate(-45deg);
        background-color: white;
    }

    .line:focus{
        outline: none;
    }
`
const toggle = styled.div`
    
`

const Button = styled.div`

    display: flex;
    gap: 20px;
    margin: 2rem 0;
    margin-bottom: 4rem;
    align-items: center;

    @media (max-width:420px) {
        flex-direction: column;
        align-items: start;
    }


    a{
        font-size: clamp(1rem, .5vw, 5rem); 
        color: black;
        text-transform: uppercase;
    }
    
    .meetTheTeam{
        color: white;
        border: none;
        border: 1px solid ;
        padding-right: .4rem;
        padding-left: 1rem;
        border-radius: 30px;
        border: 1px solid #fff;
        padding: 1rem 2rem;
        
    }    
`

const Donate = styled.div`
    display: flex;
    align-items: center;
    border: none;
    padding-right: .4rem;
    gap: 10px;
    border-radius: 30px;
    background-color: #FDCB4A;
    z-index: -1;
    cursor: pointer;
    
    p{
        padding: 1rem .5rem;
        padding-right: .4rem;
        padding-left: 1rem;
    }
    
    
`

const Socials = styled.div`



    @media (max-width:420px) {
        margin-top: -1.5rem;
    }

    .svg-container{

        display: flex;
        gap: 30px;

        .svg-component svg{
            width: 35px;
            fill: white;
            display: flex;
            transition: 0.2s all ease-in-out;

            &:hover{
                fill: #FDCB4A;
            }
        }
        

        
    }
    
`

