import React, { useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const OurProjects = () => {

  return (
    <Container>

        <CardContainer >


            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/1.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>Campaign</h2>
                
                    <p>
                        Awareness sparks conversation and ignites us to 
                        find solutions. So far, We have reached 6000 Students 
                        in South India through Human Trafficking Seminars 
                        and Various Awareness Campaigns
                    </p>
                </div>
                
            </Card>

            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/2.jpg" alt="" />
                </div>

                <div className='content'>
                    <div className='content'>
                    <h2>COMPUTER TRAINING</h2>
                
                    <p>
                        Basic Computer Training course is exclusively 
                        curated for Rescued Bonded Labourers by Cross 
                        Global in partnership with Rescued Bonded Labour 
                        Association. Cross Global started the course for 
                        the RBLA leaders on Feb 1st 2020. The syllabus 
                        of the course includes all basic operations 
                        involved in computing activity. So far, 6 Leaders 
                        of RBLA has successfully completed the Course
                    </p>
                </div>
                </div>
                
            </Card>

            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/3.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>SPOKEN ENGLISH</h2>
                
                    <p>
                        Speaking English allows the survivors to 
                        actually broaden their world, from using 
                        mobile phones to job opportunities. Our 
                        Team has exclusively curated a Basic Spoken 
                        English Course for The Survivors Rescued from 
                        Human Trafficking
                    </p>
                </div>
                
            </Card>

            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/4.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>SPORTS ACADEMY</h2>
                
                    <p>
                        The goal of this project is to prevent 
                        vulnerable children from school dropouts, 
                        child marriages, child labor, drug 
                        addictions. And, increase their self-esteem 
                        and self-confidence by using sports
                    </p>
                </div>
                
            </Card>

            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/5.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>PUBLIC POLICY ENGAGEMENT</h2>
                
                    <p>
                        Cross Global engages with Government 
                        agencies and lawmakers to advance a human 
                        rights-based approach to human trafficking. 
                        We promote policies that address the root 
                        causes of trafficking, guarantee the protections 
                        and rights of trafficked persons, and encourage 
                        survivor leadership in efforts to combat human 
                        trafficking
                    </p>
                </div>
                
            </Card>

            <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/6.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>LIVELIHOOD</h2>
                
                    <p>
                        We Rehabilitate survivors through livelihood skills 
                        with the hope of reintegrating them back into society. 
                        Our Goal is to promote livelihood options for 
                        adolescent tribal women in rural areas.
                        <br />
                        <br />
                        It places women’s security and human rights at 
                        the centre of its efforts, promoting women’s 
                        rights, opportunities and capacities through 
                        this initiative. With support from SRLM & RBLA, 
                        with an aim to create sustainable livelihood 
                        options to protect and prevent women from being 
                        trafficked.
                    </p>
                </div>
                
            </Card>

            {/* <Card>

                <div className='imageContainer'>
                    <img src="./images/ourProjects/7.jpg" alt="" />
                </div>

                <div className='content'>
                    <h2>AHT LEARNING</h2>
                
                    <p>
                        AHT Learning is an online program. An opportunity 
                        to learn from the leading organizations and Human 
                        rights defenders across the globe. This program 
                        has launched with a view that it would help 
                        learners to understand the various contours 
                        of human trafficking.
                        <br />  
                        <br />  
                        We believe that this program would enable the 
                        learner to know about the rescue and rehabilitation 
                        of the victims and prosecuting the offenders. 
                        Further, this program will also allow the learners 
                        to have hands-on ground with Cross Global in the 
                        rescue and rehabilitation programs
                    </p>
                </div>
                
            </Card> */}
            

        </CardContainer>

    </Container>
  )
}

export default OurProjects

const Container = styled.div`
    max-width: 1300px;
    margin: 0 auto;
`

const CardContainer = styled.div`
    /* margin: 10rem 0; */
`

const Card = styled.div`

    display: flex;
    gap: 8rem;
    margin: 6rem 2rem;

    @media (max-width:1180px) {
        gap: 4rem;
        margin: 4rem 2rem;
    }

    @media (max-width:980px) {
        flex-direction: column;
    }
    

    padding-bottom: 3rem;
    border-bottom: 1px solid black;

    .imageContainer{

        img{
            width: 500px;
            border-radius: 9px;

        @media (max-width:570px) {
            width: 100%;
        }

        }
    }

    .content{

       

        h2{
            font-size: clamp(2rem, 3vw, 6rem);
            padding-bottom: 2rem;
            text-transform: uppercase;
        }

        p{
            font-size: clamp(1.2rem, 1.5vw, 1.4rem);
            
        }
    }

`

