import React, { useEffect, useState} from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import OurSolution from './components/OurSolution'
import {Route, Routes, useLocation} from 'react-router-dom'
import Main from './components/Main'
import HumanTrafficking from './components/HumanTrafficking'
import Contact from './components/Contact'
import Volunteer from './components/Volunteer'
import NewLetter from './components/NewLetter'
import Donate from './components/Donate'
import MeetTheTeam from './components/MeetTheTeam'
import RegistrationDetails from './components/RegistrationDetails'
import Financial from './components/Financial'
// import PopUp from './components/PopUp'
import DemoForm from './components/DemoForm'
import AnnualReports from './components/AnnualReports.jsx'




const App = () => {

  const [pop, setPopup] = useState(false)

  const ht = window.location.href === 'https://www.crossglobalindia.org/humantrafficking'
  const main = window.location.href === 'https://crossglobalindia.org/'
  
  const {pathname} = useLocation();
  useEffect(()=>{
    console.log(window)
    window.scrollTo(0,0);
  },[pathname])

  useEffect(() =>{
    setTimeout(() =>{
      setPopup(true)
    }, 3000)
  },[])


  const close = () =>{
    setPopup(false)
  }


  return (

    // get regular ubdates about human trafficiking and cross global

    <>

      {/* { main && pop ? <PopUp close = {close} /> : ''} */}

      {
        ht? '':<NewLetter />
      }
      <Navbar />
        <Routes>

          <Route path='/' element={<Main />} />
          <Route path='/humantrafficking' element={<HumanTrafficking />} />
          <Route path='/ourproject' element={<OurSolution/>} />
          <Route path='/contactus' element={<Contact />} />
          <Route path='/volunteer' element={<Volunteer />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/meettheteam' element={<MeetTheTeam />} />
          <Route path='/registrationdetail' element={<RegistrationDetails />} />
          <Route path='/financial' element={<Financial />} />
          <Route path='/annualreports' element={<AnnualReports />} />
          <Route path='/demoform' element={<DemoForm />} />
      
        </Routes>      
      <Footer />


      

    </>
  )
}

export default App