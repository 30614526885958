import React, { useRef, useEffect} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const HeroImg = () => {

    const containerRef = useRef(null)

    useEffect(()=>{
        const el = containerRef.current
        gsap.to(el.querySelector('.mainImage'),{
            delay:2,
            duration: 2,
            clipPath:'inset(0% 0% round 20px)',
            scrollTrigger:{
                trigger: el,
                // markers:true,
                start: 'top 10%',
                end:'70% 30%',
                pin:el.querySelector('.mainImage'),
                pinSpacing: false,
                scrub:1.5,
            }
        })

        gsap.to(el.querySelectorAll('.left'),{
            opacity:0,
            scrollTrigger:{
                trigger: el,
                // markers:true,
                start: 'top 10%',
                end:'70% 30%',
                scrub: 1,
            }
        })

        gsap.to(el.querySelectorAll('.right'),{
            opacity:0,
            scrollTrigger:{
                trigger: el,
                // markers:true,
                start: 'top 10%',
                end:'70% 30%',
                scrub: 1,
            }
        })

    },[])


  return (
    <Main ref={containerRef}>

        <PhoneImage>
            <img src="./images/main/4.jpg" alt="" />
        </PhoneImage>

        <MainImage className='main'>
            <img className='mainImage' src="./images/main/4.jpg" alt="" />
        </MainImage>

        <Container>
            <div className=' left img-container'>
                <img src="./images/main/1.jpg" alt="" />
            </div>

            <div className='left top img-container'>
                <img src="./images/main/2.jpg" alt="" />
                <img src="./images/main/3.jpg" alt="" />
            </div>

            <div className='center img-container'>
                <img src="./images/main/4.jpg" alt="" />
            </div>

            <div className='right top img-container'>
                <img src="./images/main/5.jpg" alt="" />
                <img src="./images/main/6.jpg" alt="" />
            </div>

            <div className=' right img-container'>
                <img src="./images/main/7.jpg" alt="" />
            </div>
        </Container>
    </Main>
  )
}

export default HeroImg

const Main = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: 100%;
    margin: 0 auto; */


    
`

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    gap: 30px;

   
        @media (max-width:1145px) {
           display: none;
        }


    .img-container{

        /* width: 100%; */
        z-index: -1;

        img{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 300px;
            object-fit: cover;
            border-radius: 9px;
        }
    }

    .top{
        position: relative;
        top: -50px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        z-index: -1;
    }

    .center{
        visibility: hidden;
    }
`

const MainImage = styled.div`
    width: 100%;
    /* height: auto; */
    position: absolute;
    /* z-index: -1; */
    /* filter: drop-shadow(0px 10px 30px #00000077); */
    img{
        width: 100%;
        clip-path: inset(15% 35% round 20px);
        
    }
    @media (max-width:1145px) {
           display: none;
    }
`

const PhoneImage = styled.div`

    margin: 0 1rem;

    img{
        width: 100%;
        height: 400px;
        object-fit: cover;
        border-radius: 9px;
    }
    @media (min-width:1145px) {
        display: none;
    }
`