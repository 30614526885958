import React from 'react'
import styled from 'styled-components'

const Volunteer = () => {
  return (
    <Container>
        {/* <iframe  class="google-from"  title="myFrame" src="https://docs.google.com/forms/d/e/1FAIpQLSfR1W02zaYLFyRTElkPoUbRZ0MaDIT9NSy2SyuP1yBDXVnKhw/viewform?embedded=true" width="640" height="2360" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
        <iframe class="google-from"  title="myFrame" src="https://docs.google.com/forms/d/e/1FAIpQLSfR1W02zaYLFyRTElkPoUbRZ0MaDIT9NSy2SyuP1yBDXVnKhw/viewform?embedded=true" width="640" height="2463" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </Container>
  )
}

export default Volunteer

const Container = styled.div`
    margin-top: 9rem;
    display: flex;
    justify-content: center;
    /* background-color: #FDCB4A; */
`