import React from 'react'
import styled from 'styled-components'
import OurImpact from './OurImpact'


const AboutUs = () => {
  return (
    <>
    
    <Container>
        <Head>
          <p className='title'>About Us</p>
          <p className='content'>
            Cross Global Asia Foundation is a registered <b>non-religious </b> 
            and <b>non-profit organization</b> that protects the most vulnerable 
            from human trafficking, irrespective of their gender, religion, 
            community, caste, race, or ethnicity.
            <br />
            <br />
            Cross Global Asia Foundation works with government agencies, 
            civil societies, corporations, and other stakeholders to prevent, 
            rescue, and rehabilitate the victims of human trafficking.
          </p>
        </Head>
    </Container>

    <OurImpact />

    </>
  )
}

export default AboutUs

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 6rem;
    overflow: hidden;

    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 2rem 2rem;
    margin-top: 5rem;

    @media (max-width:900px) {
      padding: 0 2rem;
      /* height: 60vh; */
    }
`

const Head = styled.div`

  .title{
    padding: 3rem 0;
    font-size: clamp(1rem, .5vw, 1rem);
    text-transform: uppercase;
    opacity: 0.5;
    letter-spacing: 2px;
    font-family: skb;

    @media (max-width:700px) {
      margin-top: 0rem;
      padding: 2rem 0;
    }
    
  }
  .content{
    font-size: clamp(1.7rem, 4vw, 2rem);
    border-bottom: 0.5px solid #2929298e;
    padding-bottom: 5rem;

    b{
      color: var(--yellow-color);
    }

    @media (max-width:500px) {
      padding-bottom: 2rem;
      font-size: clamp(1.3rem, 2vw, 2rem);
    }

    .highlights{
      color: #E5002B;
    }
  }
`


