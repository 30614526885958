import React, { useState } from 'react'
import styled from 'styled-components'
import BankDetails from './BankDetails'

const MoreWaysToPay = () => {

    const [fiveHundred, setfiveHundred] = useState('500')
    const [thousand, setThousand] = useState('1000')
    const [twoFive, setTwoFive] = useState('2500')
    const [fiveThousand, setFiveThousand] = useState('5000')
    const [fifteenThousand, setFifteenThousand] = useState('15000')
    const [twentyFive, setTwentyFive] = useState('25000')
    const [fifty, setFifty] = useState('50000')


    const handleSubmitFiveHundred = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: fiveHundred*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitThousand = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: thousand*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitTwoKFive = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: twoFive*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitFiveThousand = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: fiveThousand*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitFifteenThousand = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: fifteenThousand*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitTwentyFive = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: twentyFive*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitFifty = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: fifty*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }


  return (

    <>
    
    <Container>

        <CardContainer>

            <h1>How your donation makes a difference</h1>

            <Cards>
                
                <section>
                    <h2>₹{fiveHundred}</h2>
                    <p>Could cover one week of Digital & Financial literacy training for a survivor.</p>
                    <button onClick={handleSubmitFiveHundred}>Donate now</button>
                </section>

                <section>
                    <h2>₹1000</h2>
                    <p>could cover a month for a child’s aftercare program.</p>
                    <button onClick={handleSubmitThousand} >Donate now</button>
                </section>

                <section>
                    <h2>₹2500</h2>
                    <p>could provide medical care for a survivor.</p>
                    <button onClick={handleSubmitTwoKFive}>Donate now</button>
                </section>

                <section>
                    <h2>₹5000</h2>
                    <p>could provide local training to teach a community how to stop violence before it starts.</p>
                    <button onClick={handleSubmitFiveThousand}>Donate now</button>
                </section>

                <section>
                    <h2>₹1500</h2>
                    <p>could cover one-month expenses of our aftercare centre.</p>
                    <button onClick={handleSubmitFifteenThousand}>Donate now</button>
                </section>

                <section>
                    <h2>₹25000</h2>
                    <p>Help us to educate a girl child pursuing Bachelors's/Masters Degree.</p>
                    <button onClick={handleSubmitTwentyFive}>Donate now</button>
                </section>

                <section>
                    <h2>₹50000</h2>
                    <p>Help a survivor to start a business</p>
                    <button onClick={handleSubmitFifty}>Donate now</button>
                </section>
            </Cards>
        </CardContainer>

        

    </Container>

    <BankDetails />

    </>
  )
}

export default MoreWaysToPay

const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 2rem;
`

const CardContainer = styled.div`
    h1{
        margin-bottom: 5rem;
    }

    margin: 6rem 0;
`

const Cards = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    /* grid-template-columns: repeat(2,1fr); */
    grid-gap: 50px;

    @media (max-width:1090px) {
      grid-template-columns: repeat(3,1fr);
      grid-gap: 30px;
    }

    @media (max-width:733px) {
      grid-template-columns: repeat(2,1fr);
    }

    @media (max-width:500px) {
      grid-template-columns: repeat(1,1fr);
    }

    section{
        /* background-color: #ededed; */
        background-color: #1E1E1E;
        padding: 2rem;
        border-radius: 9px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2{
            color: white;
        }

        p{
            color: white;
            padding: 1rem 0;
            font-size: clamp(1rem, 2vw, 1.2rem);
        }

        button{
            border: none;
            padding: .5rem 1.7rem;
            background-color:transparent;
            border-radius: 7px;
            color: white;
            border: 1px solid white;
            transition: .3s all ease-in-out;
            font-family: skb;

            &:hover{
                 background-color: #FDCB4A;
                 border: 1px solid #FDCB4A;
                 color: #1E1E1E;
            }
        }
    }
`