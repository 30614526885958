import React from 'react'
import styled from 'styled-components'

const BankDetails = () => {
  return (
    

    <>

    
    
        <TextWrapper>
            <h1 className='rolling-text' >More ways to give</h1>
            <h1 className='rolling-text' >More ways to give</h1>
            <h1 className='rolling-text' >More ways to give</h1>
            <h1 className='rolling-text' >More ways to give</h1>
            <h1 className='rolling-text' >More ways to give</h1>
            <h1 className='rolling-text' >More ways to give</h1>
        </TextWrapper>

        <Container>

        <Cards>

            <Left>

                <section>
                    <h2>(SCAN QR)</h2>
                    <div>
                        <img className='qr' src="./images/qrcode.png" alt="" />
                    </div>
                </section>

                <section>
                    <h2>Online Bank Transfer</h2>
                    <div>
                        <p>Bank Name: <b>HDFC</b></p>
                        <p>Account Name: <b>Cross Global Asia Foundation</b></p>
                        <p>Account number: <b>50200050860289</b></p>
                        <p>IFSC Code: <b>HDFC0009077</b></p>
                    </div>
                </section>

                <section>
                    <h2>UPI</h2>
                    <div>
                        <p><b>9500179125@hdfcbank</b></p>
                    </div>
                </section>

            </Left>
            
            <Right>

                <section>
                    <h2>Cheques</h2>
                    <div>
                        <p>Please make payable to <b>“CROSS GLOBAL ASIA FOUNDATION”</b></p>
                        <p>Cheques can be mailed to: <b>Plot 89, 1st Circular Street, Secretariat Colony, Thoraipakkam, Chennai - 600 097</b></p>
                    </div>
                </section>
            </Right>
            
        </Cards>
        

        <Section>
            
            <SectionOne>
                <h2>Special Donations</h2>
                <p>
                    If you do not indicate a specific area, 
                    your donation will go into the general 
                    fund and be used where needed. However, 
                    if desired, when making your donation you 
                    can indicate one or more of the following 
                    specific Cross Global Projects where you would 
                    like your donation to be used. Please send an 
                    email to contact@crossglobalindia.org with the 
                    amount transferred, transaction reference number, 
                    and the project to which you wish to designate 
                    your donation to
                </p>
            </SectionOne>

            <SectionTwo>

                <div className='container'>
                    <h2>Accountability & Transparency</h2>
                    <img src="./images/GSI-logo.png" alt="" />
                </div>
                <div>
                    <p>
                        Cross Global is committed to modelling and maintaining 
                        the highest standards in our work, including our commitment 
                        to financial integrity. Cross Global is recognized by GuideStar 
                        India (GSN: 13580) for our exceptional commitment to financial 
                        responsibility, accountability and transparency. You may view our 
                        full audited financial statements at www.crossglobalindia.org
                    </p>
                </div>

            </SectionTwo>

            <SectionThree>
                
                <div className='left'>
                    <h2>Tax Exemption under 80G</h2>
                    <p>
                        The income tax rules to claim an 80G deduction 
                        are changed from the assessment year 2022-23 Know 
                        moreCross Global Asia Foundation is registered u/s 
                        12A of the Income Tax Act, 1961, and with the Director 
                        of Income Tax (Exemptions) u/s 80G, valid until 
                        AY 2024- 2025 (Approval no. AAICC9858RE20206)
                    </p>
                </div>
                
                <div className='right'>
                    <h2>More Information</h2>
                    <p>
                        We do not have a facility to accept overseas contributions.
                        <br />
                        Made A Donation & Haven't gotten your receipt? Please 
                        reach out to us, at +91 89409 18981 or email at 
                        contact@crossglobalindia.org
                    </p>
                </div>

            </SectionThree>
        </Section>

    </Container>

    </>
  )
}

export default BankDetails

const Container = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: 9rem auto;    

    h1{
        margin-bottom: 5rem;
    }
`

const TextWrapper = styled.div`

    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 6rem 0;
    padding-bottom: 0;

    .rolling-text{
        
        font-size: clamp(3rem, 3vw, 5rem);
        color: #FDCB4A;
        white-space: nowrap;
        padding: .5rem 3rem;
        animation: move-rtl 6000ms linear infinite;
        
    }

    @keyframes move-rtl {
        0%{
            transform: translateX(0);
        }

        100%{
            transform: translateX(-100%);
        }
    }
`

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    padding: 0 2rem;

    h2{
        padding-bottom: 1rem;
        font-size: clamp(2rem, 2vw, 2.5rem);
    }

    p{
        padding: .3rem 0;
        font-size: clamp(1rem, 2vw, 1.2rem);
    }

`

const Left = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;

    @media (max-width:1046px) {
     grid-template-columns: repeat(2, 1fr);
     grid-gap: 3rem;
    }

    @media (max-width:680px) {
     grid-template-columns: repeat(1, 1fr);
    }

    .qr{
        width: 200px;
    }

`

const Right = styled.div`
    width: 50%;

    @media (max-width:746px) {
        width: 100%;
    }
`


const Section = styled.div`

    margin: 9rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    border-top: 1px solid black;
    padding-top: 3rem;

    h2{
        padding-bottom: 2rem;
        font-size: clamp(2rem, 2vw, 2.5rem);
    }

    p{
        padding: .3rem 0;
        font-size: clamp(1rem, 2vw, 1.2rem);
    }
    
`

const SectionOne = styled.div`


    
`

const SectionTwo = styled.div`
    .container{
        display: flex;
        align-items: center;
        gap: 5rem;

        @media (max-width:766px) {
            flex-direction: column;
            align-items: start;
            gap: 0rem;
        }
        img{
            position: relative;
            top: -24px;
            width: 200px;
        }
    }
`

const SectionThree = styled.div`
    width: 100%;
    display: flex;
    gap: 4rem;

     @media (max-width:970px) {
        flex-direction: column;
    }

    .left{
        width: 50%;

        @media (max-width:970px) {
            width: 100%;
        }
    }

    .right{
        width: 50%;
        
        @media (max-width:970px) {
            width: 100%;
        }
    }


   

`