import React, { useState } from 'react'
import styled from 'styled-components'
import MoreWaysToPay from './MoreWaysToPay'


const Donate = () => {

  const [fiveHundred, setfiveHundred] = useState('500')
  const [thousand, setThousand] = useState('1000')
  const [twoThousand, setfivetwoThousand] = useState('2000')
  
  const [amount, setAmount] = useState('')



    const handleSubmitAmount = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: amount*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

    const handleSubmitFiveHundred = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: fiveHundred*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }
    const handleSubmitThousand = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: thousand*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }
    const handleSubmitTwoThousand = (e)=>{

    e.preventDefault();

    if(fiveHundred === ""){

      alert("please enter amount");

    }else{

      var options = {
        key: "rzp_live_JUfWV71vhVwqsm",
        key_secret:"6KAsa6IVL9gHCKD5vpPbMuHy",
        amount: twoThousand*100,
        currency:"INR",
        name:"Cross Global",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Reshil",
          email:"reshilobeth5510@gmail.com",
          contact:"9790392441"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#FDCB4A"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  }

  return (
    
    <>
    <Container>
        <Left>

          

          <PayForm>
            <Amount>
              <Button>
                <button onClick={handleSubmitFiveHundred} className='button'>₹ 500</button>
                <button onClick={handleSubmitThousand} className='button'>₹ 1000</button>
                <button onClick={handleSubmitTwoThousand} className='button'>₹ 2000</button>          
              </Button>

              <CustomPay>
                <input type="text" placeholder='Custom Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
              </CustomPay>

            </Amount>

              <br />
              <button onClick={handleSubmitAmount} className='payNow'>Donate</button>
          </PayForm>

          <Content>
            <p>
              Cross Global Asia Foundation, a not-for-profit 
              company u/s 8 of the Companies Act, 2013. 
              (Reg. No. AAICC9858RF20210), with the RoC, Chennai.
              <br />
              <br />
              Cross Global Asia Foundation is registered u/s 
              12A of the Income Tax Act, 1961, and with the 
              Director of Income Tax (Exemptions) u/s 80G, 
              valid until AY 2024- 2025 (Approval no. AAICC9858RE20206)
            </p>
          </Content>

        </Left>

      <Right>
        <div className='imgContainer'>
          <img src="./images/donate.png" alt="" />
        </div>
      </Right>

    </Container>
    <MoreWaysToPay />
    </>
  )
}

export default Donate

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem 2rem;
  margin-top: 9rem;
  justify-content: space-between;
  align-items: center;

  @media (max-width:821px) {
    flex-direction: column;
    gap: 3rem;
  }



`

const Left = styled.div`
  width: 50%;

  @media (max-width:821px) {
    width: 100%;
  }
`

const Right = styled.div`
  width: 50%;

  @media (max-width:821px) {
    width: 100%;
  }

    .imgContainer{
      
      img{
        width: 90%;

        @media (max-width:1029px) {
          width: 100%;
        }
      }
    }
`

const Content = styled.div`
  p{
    padding: 4rem 4rem 2rem 0rem;
    font-size: clamp(1rem, 1.5vw, 1.2rem);

    @media (max-width:420px) {
      padding: 0rem;
      padding-top: 2rem;
    }

  }
`



const PayForm = styled.div`
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 9px;
  width: 350px;

  @media (max-width: 420px) {
    width: 100%;
  }
  

  .payNow{
    width: 100%;
    padding: .7em;
    border-radius: 9px;
    color: white;
    background-color: black;
  }
`

const Amount = styled.div`
  background-color: #FDCB4A;
  padding: 1.5rem 1rem;
  border-radius: 9px;
  /* width: 100%; */
`

const Button = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  button{
    /* margin-right: 1rem; */
    font-size: clamp(1.2rem, 1vw, 1.3rem);
    font-family: skb;
    border: none;
    padding: .5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    background-color: white;

    /* font-family: ; */
  }
  
`

const CustomPay = styled.div`
  input{
    border: none;
    padding: .5rem;
    border-radius: 5px;
    width: 100%;
    font-size: clamp(1rem, 1vw, 1rem);
  }
`



// {<input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} /> 