import React from 'react'
import styled from 'styled-components'

const Contact = () => {
  return (
    <Container>
      
      <Left>
          <h1>Contact Us</h1>
          <div>
            <img src="/icons/location.png" alt="" />
            <p className='address'>
              Cross Global Asia Foundation <br />
              A4, Chandrasekaran avenue, 1st Main Road,<br />
              Thoraipakkam, Chennai - 600097
            </p>
                                   
          </div>
          <div>
            <img src="/icons/phone.png" alt="" />
            <p className='address'>+91 95001 79125</p>
          </div>
          <div>
           <img src="/icons/email.png" alt="" />
            <p className='address'>contact@crossglobalindia.org</p>
          </div>
          
          <iframe title="myFrame" className='iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5846142560094!2d80.22952121117707!3d12.934397987324894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d32d22d373f%3A0x8478843a87569e31!2sCross%20Global%20India!5e0!3m2!1sen!2sin!4v1695738797667!5m2!1sen!2sin" style ={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      </Left>
      <Right>
        <form action="https://formspree.io/f/mknlkygd" method="POST">
          <input type="text" name='Name' placeholder="Your Name" required />
          <input type="email" name='E-mail' placeholder="Your e-mail" required />
          <input type="tel" name='Ph.no' placeholder="Phone number" />
          {/* <br /> */}
          <textarea name='Message' placeholder="Enter your message here" id="" cols="0" rows="8"></textarea>
          <button type='submit'>Submit</button>
        </form>
      </Right>
    </Container>
  )
}

export default Contact

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4rem 7rem;
  width: 100%;
  padding-top: 10rem;

  @media (max-width:1100px) {
   padding :4rem 3rem ;
   padding-top: 10rem;
  }
  @media (max-width:950px) {
   flex-direction: column;
   gap: 60px;
  }
  @media (max-width:500px) {
   padding :4rem 2rem ;
   padding-top: 8rem;
  }
`

const Left = styled.div`
iframe{
      margin-top: 3rem;
      border-radius: 20px;
      width: 100%;
      height: 300px;
    }
div{
  
  display: flex;
  img{
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }
  .address{
    font-size: clamp(1rem, 1vw, 1.5rem);
    padding-bottom: .7rem;
    line-height: 25px;
  }
}
  h1{
    font-size: clamp(2.5rem, 6vw, 4rem);
    padding-bottom: 2rem;
  }
  
`

const Right = styled.div`
    background-color: #f4f4f4;
    padding: 4rem;
    width: 50%;
    border-radius: 20px;
    @media (max-width:950px) {
      width: 100%;
    }

    @media (max-width:550px) {
      padding:2.5rem 2rem;
    }
  form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    input, textarea, button{
      border: none;
      padding: 1rem 1rem;
      border-radius: 5px;
      font-size: clamp(1rem, 1vw, 1.5rem);

      @media (max-width:550px) {
        font-size: clamp(.8rem, 1vw, 1.5rem);
      }
    }

    button{
      background-color: #FDCB4A;
      color: white;
    }
  }
`