import React from 'react'
import styled from 'styled-components'

const OurImpact = () => {
  return (
    <Container>
        <TextWrapper>
            <h1 className='rolling-text' >Our Impact</h1>
            <h1 className='rolling-text' >Our Impact</h1>
            <h1 className='rolling-text' >Our Impact</h1>
            <h1 className='rolling-text' >Our Impact</h1>
            <h1 className='rolling-text' >Our Impact</h1>
            <h1 className='rolling-text' >Our Impact</h1>
        </TextWrapper>

      <Main>
        <Content>
        
        <Card>
          <div className='content'>
            <p>Awareness</p>
            <h2>100,000+</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/1.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Training</p>
            <h2>430</h2>
          </div>
          <div className='imgContainer'>
            <img className='secImg' src="./images/ourImpact/2.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Victims Identifications</p>
            <h2>160+</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/3.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Survivors Supported</p>
            <h2>938</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/4.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Legal Assistance</p>
            <h2>10</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/5.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>COVID Response</p>
            <h2>528</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/6.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Natural Disaster Relief</p>
            <h2>320</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/7.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Education Support</p>
            <h2>235</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/8.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>House Construction</p>
            <h2>8</h2>
          </div>
          <div className='imgContainer'>
            <img className='nineImg' src="./images/ourImpact/9.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Toilet Construction</p>
            <h2>6</h2>
          </div>
          <div className='imgContainer'>
            <img src="./images/ourImpact/10.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Digital Literacy for <br /> Survivors of Bonded Labour</p>
            <h2>27</h2>
          </div>
          <div className='imgContainer'>
            <img className='big' src="./images/ourImpact/11.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Spoken English Classes for Survivors of Bonded Labour</p>
            <h2>22</h2>
          </div>
          <div className='imgContainer'>
            <img className='big'  src="./images/ourImpact/12.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Sports Academy</p>
            <h2>50</h2>
          </div>
          <div className='imgContainer'>
            <img className='oneThreeImg' src="./images/ourImpact/13.png" alt="" />
          </div>
        </Card>
        
        <Card>
          <div className='content'>
            <p>Partnerships</p>
            <h2>23</h2>
          </div>
          <div className='imgContainer'>
            <img className='oneFourImg' src="./images/ourImpact/14.png" alt="" />
          </div>
        </Card>

        
      </Content>
      </Main>

    </Container>
  )
}

export default OurImpact

const Container = styled.div`
    /* height: 100vh; */
    /* display: flex; */
    /* justify-content: center; */
    /* flex-direction: column; */
    /* align-items: center; */
    margin-bottom: 9rem;
`

const TextWrapper = styled.div`

    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 6rem 0;

    .rolling-text{
        
        font-size: clamp(3rem, 3vw, 5rem);
        color: #FDCB4A;
        white-space: nowrap;
        padding: .5rem 3rem;
        animation: move-rtl 6000ms linear infinite;
        text-transform: uppercase;
        
    }

    @keyframes move-rtl {
        0%{
            transform: translateX(0);
        }

        100%{
            transform: translateX(-100%);
        }
    }
`

const Main = styled.div`
  display: flex;
`

const Content = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  

  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-gap: 2rem;
  

  justify-content: center;
  align-items: center;
  /* display: flex; */

  @media (max-width:1180px) {
    grid-template-columns: repeat(3,1fr);
  }

  @media (max-width:898px) {
    grid-template-columns: repeat(2,1fr);
  }

  @media (max-width:617px) {
    grid-template-columns: repeat(1,1fr);
  }

`

const Card = styled.div`

  background-color: #1E1E1E;
  border-radius: 9px;
  color: white;
  width: 250px;
  height: 320px;
  overflow: hidden;

  

  .content{
    padding: 1.5rem;
  }

  p{
    font-size: clamp(1.2rem, 1vw, 1.5rem);
    /* border-bottom: .5px solid #ffffff8a; */
    /* padding-bottom: 1rem; */
  }


  h2{
    font-size: clamp(2.5rem, 3vw, 8rem);
    /* padding-top: 0.5rem; */
  }


  .imgContainer{
    position: relative;
    /* overflow: hidden; */
    width: 200px;
  }
  
  img{
   width: 100%;
    margin-top: 2rem;
    position: relative;
    right: 30px;
    /* top: 30px; */
    overflow: hidden;
    transition: 0.3s all ease-in-out;
    
  }

  .secImg{
    padding-top: 1rem;
  }

  .oneFourImg{
    padding-top: 2rem;
    width: 220px;
  }

  .nineImg{
    width: 155px;
  }

  .oneThreeImg{
    width: 180px;
    right: 15px;
  }

  &:hover img{
    transform: scale(1.1);
  }

  .big{
    width: 180px;
    margin-top: .2rem;
  }

  

`