import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <>
    
        <Container id='home'>

            <Title>
                <h1>
                    End Human Trafficking in <br /> Our Lifetime
                </h1>
            </Title>

                <Button>
                    <Link to='/meettheteam'  className='meetTheTeam'>MEET THE TEAM </Link>
                    <Link to='/donate' className='donate'>
                        <p> DONATE </p>
                        <img src="./icons/arroww.png" alt="" />
                    </Link>

                </Button>
        

        </Container>
    </>
  )
}

export default Home

const Container = styled.div`
    width: 100%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    /* position: relative; */
    /* top: 30; */
    margin-top: 9rem;
    margin-bottom: 4rem;
    
`

const Title = styled.div`
    h1{
        font-size: clamp(2rem, 6vw, 5rem); 
        text-align: center;
        /* max-width: 1500px; */
        /* line-height: 90px; */

        @media (max-width:340px) {
            
            font-size: clamp(1.7rem, 6vw, 5rem); 
            
        }
    }
    
`

const Button = styled.div`

    display: flex;
    gap: 20px;
    align-items: center;
    margin: 1rem 0 3rem 0;

    .donate{
        background-color: #FDCB4A;
        display: flex;
        align-items: center;
        padding: .5rem 1rem;
        border-radius: 30px;
        padding-right: 0.5rem;
        transition: 0.2s all ease-in-out;
        

        &:hover {
            padding-right: 1.1rem;
        }

        &:hover img{
            transform: translateX(12px);
        }

        p{
            padding-left: 0.5rem;
            color: black;
        }

        img{
            width: 30px;
            margin-left: .5rem;
            transition: 0.2s all ease-in-out;

            /* &:hover{
                transform: translateX(9px);
            } */
        }
    }

    p{
        font-size: clamp(1rem, .5vw, 5rem); 
    }
    
    .meetTheTeam{
        border: none;
        padding: .9rem 1rem;
        border-radius: 30px;
        border: 1px solid black;
        background-color: transparent;
        cursor: pointer;
        color: black;
        transition: .3s all ease-in-out;

        &:hover{
            background-color: #FDCB4A;
            border-color: #FDCB4A;
        }
    }    

    .invert{
        border: none;
        background-color: #FDCB4A;
    }



`