import React from 'react'
import Home from './Home'
import HeroImg from './HeroImg'
import AboutUs from './AboutUs'
import Reports from './Reports'
import Title from './Title'
import NewLetter from './NewLetter'

const Main = () => {
  return (
    
    <>
    <Home />
    <HeroImg />
    <Title />
    <AboutUs />
    <Reports />
    
    </>
  )
}

export default Main