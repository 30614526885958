import React from 'react'
import styled from 'styled-components'
// import OurPartners from './OurPartners'


const MeetTheTeam = () => {
  return (
    <Container>

        <Main>

            <h1>Meet the team</h1>

            <ImgContainer>

                <Card>
                    <img src="./team/1.jpg" alt="" />
                    <Content>
                        <h2>Kamal Raj</h2>
                        <p>Founder & Director</p>
                    </Content>
                </Card>
                <Card>
                    <img src="./team/2.jpg" alt="" />
                    <Content>
                        <h2>Gladson Joshua</h2>
                        <p>Co-founder</p>
                    </Content>
                </Card>
                <Card>
                    <img src="./team/3.jpg" alt="" />

                    <Content>
                        <h2>David Peter</h2>
                        <p>Director</p>
                    </Content>
                    
                </Card>

            </ImgContainer>

        </Main>

        <MainTwo>


            <h1>Advisory Council Members</h1>

            <section>
                <img src="./team/6.jpg" alt="" />
                <div className='sub-main'>
                    <h2>Dr. Emmanuel Daniel</h2>
                    <p>
                        (Asst Professor, Department of Sociology and Social Work at Christ University, Bangalore.)
                    </p>
                    <br />
                    <br />
                    <p>
                        Dr. Emmanuel has over 11 years of professional experience in teaching, research, and practice, with a specific focus on child wellbeing. In his role as a trainer, he conducts trainings in Life Skills Education, Corporate Social Responsibility, and Employee Engagement.
                        <br /> 
                        <br /> 
                        During his PhD research, Dr. Emmanuel concentrated on assessing the Quality of Life among school-going children residing in urban areas. The recommendations derived from his research were shared with the Government of Tamil Nadu and Schools to aid in policy formulation. Additionally, he has conducted various research projects among the Tribal population in the Nilgiris and Assam.
                        <br /> 
                        <br /> 
                        Furthermore, Dr. Emmanuel serves as a development consultant for numerous non-governmental organizations in India and abroad, assisting them in grant acquisition and band building. He is also a certified Sensei and holds a Black Belt Dan IV in the Shito Ryu style of Karate. Additionally, he is a skilled musician.
                        <br /> 
                        <br /> 
                        Currently, Dr. Emmanuel Daniel holds the position of Assistant Professor in the Department of Sociology and Social Work at CHRIST (Deemed to be University) in Bangalore.
                    </p>
                </div>
            </section>
            
            <section>
                <img src="./team/4.jpg" alt="" />
                <div className='sub-main'>
                    <h2>Mr. Povas</h2>
                    <p>
                        (Advocate, Madras High Court)
                    </p>
                    <br />
                    <br />
                    <p>
                        Povas is a legal professional who embarked on a journey fueled by a passion for justice and human rights. He has a Bachelor of Arts and Bachelor of Law (B.A., B.L.) from Government Law College, Coimbatore, followed by a Master of Law (M.L.) specializing in Constitutional Law and Human Rights from The Tamil Nadu Dr. Ambedkar Law University, Chennai. Enrolling as an Advocate,  honed his skills under the mentorship of Mr. Sathia Chandran, practising in the Madras High Court, tribunals, and various regulatory bodies. Beyond the courtroom, He is a dedicated social worker, offering legal aid to those in need, embodying his belief in the law's transformative power for a more just society.
                    </p>
                </div>
            </section>

            <br />

            <section>
                <img src="./team/5.jpg" alt="" />
                <div>
                    <h2>Mr. Rufus Davidson</h2>
                    <p>
                        (CSR Lead, KONE Elevator India)
                    </p>
                    <br />
                    <br />
                    <p>
                        Rufus Davidson is an accomplished CSR Lead, who is passionate about driving corporate social responsibility (CSR) and sustainability programs. His extensive experience in overseeing public and community affairs, coupled with his dedication to making a meaningful impact, has positioned him as a prominent figure in the field. Rufus excels in creating impactful and sustainable partnerships with NGOs, strategizing and scaling community service projects across India, and fostering corporate philanthropy.</p>
                        <br />
                        At Kone Elevators and Escalators, Rufus Davidson is at the forefront of the company’s CSR and sustainability efforts. Rufus provides strategic leadership in developing and executing CSR and sustainability programs in alignment with Kone’s corporate vision and values. Prior to his role at Kone, Rufus served as a social worker at a Ngo that works for leprosy-affected patients and their kids- Rising Star Outreach of India. In this position, he provided assistance and advocacy to improve children's and their families' social and psychological functioning, contributing to community welfare and development.

                </div>
            </section>
        </MainTwo>



        <LogoMain>

            <h1>Our Partners</h1>

            <LogoContainer>
                

                <LogoCard>
                    <h3>Governments</h3>
                    <ImageContainer>
                        <img src="./ourpartners/7.png" alt="" />
                        <img src="./ourpartners/10.png" alt="" />
                    </ImageContainer>
                </LogoCard>

                <LogoCard>
                    <h3>International Partners</h3>
                    <ImageContainer>
                        <img src="./ourpartners/8.png" alt="" />
                        <img src="./ourpartners/9.png" alt="" />
                        <img src="./ourpartners/3.png" alt="" />
                        <img src="./ourpartners/15.png" alt="" />
                    </ImageContainer>
                </LogoCard>

                <LogoCard>
                    <h3>NGO's</h3>
                    <ImageContainer>
                        <img src="./ourpartners/1.png" alt="" />
                        <img src="./ourpartners/2.png" alt="" />
                        <img src="./ourpartners/4.png" alt="" />
                    </ImageContainer>
                </LogoCard>
                
                <LogoCard>
                    <h3>Educational Institutions</h3>
                    <ImageContainer>
                        <img src="./ourpartners/6.png" alt="" />
                        <img src="./ourpartners/13.png" alt="" />
                        <img src="./ourpartners/14.png" alt="" />
                        <img src="./ourpartners/12.png" alt="" />
                        <img src="./ourpartners/11.png" alt="" />
                    </ImageContainer>
                </LogoCard>

            </LogoContainer>

        </LogoMain>


    </Container>
  )
}

export default MeetTheTeam

const LogoMain = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    h1{
        font-size: clamp(2rem, 6vw, 4rem);
        /* border-bottom: 0.5px black solid; */

    @media (max-width:820px) {
        padding-bottom: 2rem;
    }


    }

`

const LogoContainer = styled.div`

`

const LogoCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 2rem 0rem; */
    margin-bottom: 2rem;

    h3{
        /* border-bottom: 2px solid #c1c1c1; */
        padding:2rem;
    }
`
const ImageContainer = styled.div`

    padding: 1rem;
    background-color: #ebebeb;
    border-radius: 10px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    img{
        width: 130px;
        /* margin: 0 1rem; */

        @media (max-width:820px) {
            width: 80px;
        }
            
    }
`

const Container = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 2rem;
    margin-top: 9rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    h2{
        font-size: clamp(2rem, 2vw, 4rem);
        padding-bottom: .4rem;
    }

    @media (max-width:500px) {
        margin-top: 8rem;
    }
    
`

const MainTwo = styled.div`

    margin: 6rem 0;

    h1{
        padding: 2rem 0;
    }

    section{

        display: flex;
        padding: 3rem ;
        background-color: #7777771a;
        border-radius: 30px;
        margin: 2rem 0;
        align-items: start;

        @media (max-width:820px) {
            flex-direction: column;
        }

        img{
            width: 250px;
            height: 300px;
            border-radius: 9px;
            border-bottom: 9px solid #fdcd4a;
            object-fit: cover;
        }

        div{
            padding: 0 3rem;
            @media (max-width:820px) {
                padding: 0;
                padding-top: 2rem;
            }
        }
    }
`

const Main = styled.div`
    display: flex;
    flex-direction: column;

    /* padding: 0 2rem; */

    h1{
        font-size: clamp(2rem, 6vw, 4rem);
        padding-bottom: 2rem;
    }
`

const ImgContainer = styled.div`

    gap: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width:1227px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:820px) {
        grid-template-columns: repeat(1, 1fr);
    }

 
    img{
        width: 350px;
        border-radius: 9px;
        border-bottom: 9px solid #fdcd4a;

        @media (max-width:820px) {
            width: 100%;
        }
    }
`

const Card = styled.div`
    
`

const Content = styled.div`
    margin-top: 2rem;
    h2{
        font-size: clamp(2rem, 2vw, 4rem);
        padding-bottom: .4rem;
    }
`


