import React, { useRef, useEffect, useState} from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import OurProjects from './OurProjects'
gsap.registerPlugin(ScrollTrigger)

// OurProjects

const OurSolution = () => {

    const conRef = useRef(null)

    


  return (

    <Container>

        

         <Title>
            <h1>THIS IS HOW WE FIGHT HUMAN TRAFFICKING </h1>
            <p className='title-sub'>
                
                Governments across the world have developed 
                numerous welfare measures to meet the basic 
                needs of their citizens – especially those from 
                poorer sections of society. However, offenders 
                who seek to earn profits through exploitative 
                practices, restrict or cut off access to government’s 
                schemes by controlling the vulnerable people using 
                brute force, threats and other forms of abuse.

                <br />
                <br />

                Though countries have strong laws to prevent such 
                atrocities, offenders commit these crimes, unafraid 
                of consequences, due to the lack of implementation 
                of laws. To put an end to this cycle of injustice 
                against people in poverty, Cross Global works 
                collaboratively with the Governments and civil societies 
                by applying the following model

            </p>
        </Title>

        

        <TextWrapper>
            <h1 className='rolling-text' >Our Solution</h1>
            <h1 className='rolling-text' >Our Solution</h1>
            <h1 className='rolling-text' >Our Solution</h1>
            <h1 className='rolling-text' >Our Solution</h1>
            <h1 className='rolling-text' >Our Solution</h1>
            <h1 className='rolling-text' >Our Solution</h1>
        </TextWrapper>

        

        <Wrapper>

            <CardContainer ref={conRef} className='cardContainer'>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/1.png" alt="" />
                    </div>

                    <h3>Hotspot Prevention</h3>

                    <p>
                        We partner with CSOs to respond to the needs in the communities before they reach a critical level of risk
                    </p>
                </section>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/2.png" alt="" />
                    </div>

                    <h3>Awareness</h3>

                    <p>
                        We have established multiple resources 
                        to raise awareness on the issue of Human Trafficking
                    </p>

                
                </section>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/3.png" alt="" />
                    </div>

                    <h3>Victim Identification</h3>

                    <p>
                       we strive to support and work with Government authorities who plays an active role in rescuing victims of human trafficking
                    </p>

                </section>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/4.png" alt="" />
                    </div>

                    <h3>Legal Assistance</h3>

                    <p>
                       To provide legal services to address the concerns of victims of trafficking at every stage: i.e. prevention, rescue and rehabilitation.
                    </p>

                </section>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/5.png" alt="" />
                    </div>

                    <h3>Aftercare services</h3>

                    <p>
                        Holistic aftercare is a necessity to prevent survivors from being exploited again. We aim to see lives fully restored.
                    </p>


                </section>

                <section className='section'>
                    <div className='imageContainer'>
                        <img className='image' src="./images/ourSolution/6.png" alt="" />
                    </div>
                        
                            <h3>Survivors Leadership Training</h3>

                            <p>
                                Survivor Leadership is a central tenet of the Cross Global's approach to establishing effective 
                                anti-trafficking strategies that address prosecution, protection, and prevention.
                            </p>
                        

                </section>


            </CardContainer>
            
        </Wrapper>

        <TextWrapper>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
            <h1 className='rolling-text ourProjects' >Our Projects</h1>
        </TextWrapper>

        <OurProjects /> 

        <Map>
            

            <h1>LOCATION</h1>

            <MapContainer>

            

            <div className='indian'>
                <img className='image' src="./images/ourSolution/im.png" alt="" />
            </div>

            <div className='tn'>
                <img className='image' src="./images/ourSolution/tn.png" alt="" />
                <section>
                <div className='location'>
                    <img src="/icons/location.png" alt="" />
                    <span>Chennai</span>
                </div>
                <div className='location'>
                    <img src="/icons/location.png" alt="" />
                    <span>Chengalpattu</span>
                </div>
                <div className='location'>
                    <img src="/icons/location.png" alt="" />
                    <span>Kancheepuram</span>
                </div>
                <div className='location'>
                    <img src="/icons/location.png" alt="" />
                    <span>Thiruvallur</span>
                </div>
                <div className='location'>
                    <img src="/icons/location.png" alt="" />
                    <span>Tiruvannamalai</span>
                </div>

                </section>
            </div>
        </MapContainer>

        </Map>

    </Container>

  )
}

export default OurSolution

const Map = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
`


const MapContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 3rem;
    /* justify-content: center; */


    .indian{
        img{
            width: 500px;
        }
    }

    .tn{

        img{
            width: 460px;
        }

        display: flex;
        align-items: center;

        .location{
            display: flex;
            align-items: center;
            padding: .2rem 0;
            img{
                width:27px;
                margin: 0 5px;
            }

            span{
                font-family: skb;
            }
        }
    }

    @media (max-width: 1185px) {
        .indian{
            img{
                width: 390px;
            }
        }
        .tn{
            img{
                width: 380px;
            }
        }
    }

    @media (max-width: 1185px) {
        flex-direction: column;
    }

    @media (max-width: 542px) {
        .tn{
            flex-direction: column;
            align-items: end;

            .location{
                img{
                    width: 20px;

                }
            }
        }
    }

`




const Wrapper = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
`

const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    /* padding: 0 2rem; */

    @media (max-width:1392px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
    }

    @media (max-width:863px) {
        grid-template-columns: repeat(1, 1fr);
        /* grid-gap: 2rem; */
    }

    .section{
        border-radius: 9px;

        @media (max-width:464px) {
         width:350px ;
        }

        @media (max-width:336px) {
         width:310px ;
        }
        
        /* padding: 1.5rem 1rem 1rem 1rem; */

        &:hover .image{
            /* transform: scale(1.15); */
        }
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */

        &:hover {
            cursor: pointer;
        }

        h3{
            font-size: clamp(1.3rem, 2vw, 1.5rem);
            padding: 1.5rem 0;
        }

        p{
            font-size: clamp(1rem, 1.3vw, 1.3rem);
            /* padding-bottom: 1rem; */
            
        }

        .imageContainer{
            width: 100%;
            /* overflow: hidden; */
            /* border-radius: 9px; */
            
            img{
                /* width: 100%; */
                height: 100px;
                object-fit: cover;
                transform: scale(1.05);
                transition: 0.3s all ease-in-out;
                
            }
        }
        
    }

`

const Container = styled.div`
    margin-top: 9rem;
    /* margin-bottom: 9rem; */
`

const TextWrapper = styled.div`

    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 6rem 0;
    margin: 2rem 0;
    padding-top: 7rem;

    .rolling-text{
        
        font-size: clamp(3rem, 3vw, 5rem);
        color: #FDCB4A;
        /* -webkit-text-stroke-width: 0.01px;
        -webkit-text-stroke-color: #FDCB4A; */
        white-space: nowrap;
        padding: .5rem 3rem;
        /* letter-spacing: 10px; */
        animation: move-rtl 6000ms linear infinite;
        /* opacity: 0.5; */
        /* background-color: #000; */
        
    }

    .ourProjects{
        padding-bottom: 0rem;
    }

    @keyframes move-rtl {
        0%{
            transform: translateX(0);
        }

        100%{
            transform: translateX(-100%);
        }
    }

    

`



const Title = styled.div`

    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column; 
    gap: 30px;
    padding: 0 2rem;
    
    h1{
        font-size: clamp(2.5rem, 7vw, 6rem); 
        /* text-align: center; */
    }
    .title-sub{
        font-size: clamp(1.2rem, 2vw, 1.5rem);
        border-bottom: 1px #000 solid;
        padding-bottom: 2rem;
        /* text-align: center; */
    }
`




