import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const HumanTrafficking2 = () => {
  return (
    <Container>
        <Hn1>

            <div className='title'>
                <h1>Human Trafficking Helpline <br /> Numbers</h1>
                <p>Reporting human trafficking through helpline numbers is one of the first steps to ending human trafficking.</p>
            </div>

            <div className='nums'>
                <div>
                    <section>
                        <span>/01</span>
                    </section>
                    <section>
                        <p>Child Helpline Number:</p>
                        <h2>1098</h2>
                    </section>
                </div>
                <div>
                    <section>
                        <span>/02</span>
                    </section>
                    <section>
                        <p>Women Helpline Number:</p>
                        <h2>181</h2>
                    </section>
                </div>
                <div>
                    <section>
                        <span>/03</span>
                    </section>
                    <section>
                        <p>Bonded Labour Helpline Number:</p>
                        <h2>1800 4252 650</h2>
                    </section>
                </div>
                <div>
                    <section>
                        <span>/04</span>
                    </section>
                    <section>
                        <p>Anti-Human Trafficking Helpline <br />(Ministry of Home Affairs)</p>
                        <h2>1800 419 8588</h2>
                    </section>
                </div>
            </div>

        </Hn1>

        <Hn2>
            <div className='title'>
                <h1>Indian Embassies Helpline Numbers</h1>
                <p>Kindly be aware that phone numbers may be altered. If any of the provided contact numbers and helplines are not functional, we suggest reaching out to the appropriate local officials for help.</p>
            </div>
            
            <div className='flags-con'>
                    <section>
                        <div>
                            <img src="./images/flages/1.png" alt="" />
                        </div>
                        <div className='numbers-section'>
                            <p>Malaysia</p>
                            <h2>+601 2203 6900</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/2.png" alt="" />
                        </div>
                        <div  className='numbers-section'>
                            <p>Singapore</p>
                            <h2>+65 6737 6777</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/3.png" alt="" />
                        </div>
                        <div  className='numbers-section'>
                            <p>UAE</p>
                            <h2>00971-508995583</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/4.png" alt="" />
                        </div>
                        <div className='numbers-section'>
                            <p>Myanmar</p>
                            <h2>095419602</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/5.png" alt="" />
                        </div>
                        <div className='numbers-section'>
                            <p>Bangladesh</p>
                            <h2>01937400591</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/6.png" alt="" />
                        </div>
                        <div className='numbers-section'>
                            <p>Nepal</p>
                            <h2>+977 - 9851316807</h2>
                        </div>
                    </section>
                    <section>
                        <div>
                            <img src="./images/flages/7.png" alt="" />
                        </div>
                        <div className='numbers-section'>
                            <p>Sri Lanka</p>
                            <h2>+94773727832</h2>
                        </div>
                    </section>

            </div>
        </Hn2>

        <Laws>
            <div>
                <h2>Indian Laws Protecting Victims <br /> and Survivors of Human Trafficking</h2>
            </div>

            <div className='law-list'>
                <p>The Immoral Traffic (Prevention) <b>Act, 1956</b></p>
                <p>The Immoral Traffic (Prevention) <b>Act, 1956</b></p>
                <p>The Bonded Labour System (Abolition) <b>Act, 1976</b></p>
                <p>The Child Labour (Prohibition and Regulation) <b>Act, 1986</b></p>
                <p>The Juvenile Justice (Care and Protection) <b>Act, 2000</b></p>
                <p>The Scheduled Castes and the Scheduled Tribes (Prevention of Atrocities) <b>Act, 1989</b></p>
                <p>The Protection of Children from Sexual Offences <b>Act, 2012</b></p>
                <p>The Indian Penal Code, 1860 (certain provisions)</p>
            </div>
        </Laws>

        <Anti>
            <h2>
                <img className='sdg' src="./images/main/12.jpg" alt="" /> on Anti-Human Trafficking
            </h2>

            <section>
                <div>
                    <img src="./images/final-icon/post 1.png" alt="" />
                    <p>5.2: Eliminate all forms of violence against all women and girls in public and private spheres, including trafficking and sexual, and other types of exploitation.</p>
                </div>

                <div>
                    <img src="./images/final-icon/post 2.png" alt="" />
                    <p>8.7: Take immediate and effective measures to eradicate forced labour, end modern slavery and human trafficking and secure the prohibition and elimination of the worst forms of child labour, including recruitment and use of child soldiers. including recruitment and use of child soldiers, and by 2025 end child labour in all its forms.”</p>
                </div>

                <div>
                    <img src="./images/final-icon/post 3.png" alt="" />
                    <p>10.7: Facilitate orderly, safe, and responsible migration and mobility of people, including through the implementation of planned and well-managed migration policies.</p>
                </div>

                <div>
                    <img src="./images/final-icon/post 4.png" alt="" />
                    <p>16.2: End abuse, exploitation, trafficking and all forms of violence and torture against children.</p>
                    
                </div>
                <div>
                    <div></div>
                    <p>
                        <h3>Donate us to fight against human trafficking</h3>
                        <button><Link to='/donate'>DONATE</Link></button>
                    </p>
                    
                </div>
            </section>

        </Anti>

    </Container>
  )
}

export default HumanTrafficking2

const Container = styled.div`

    padding: 0 4rem;

    @media (max-width:500px) {
        padding: 0 2rem;
    }

`


const Anti = styled.div`


    padding-top: 8rem;

    .sdg{
        width: 300px;

        
    @media (max-width:500px) {
        width: 200px;
    }
    }

    @media (max-width:500px) {
        padding-top: 4rem;
    }

    h2{
        font-size: clamp(2rem, 3vw, 3rem);
       padding-bottom: 3rem;
    }

    section{


        div{
            
            display:flex;
            padding: 2rem 0;
            justify-content: space-between;
            align-items: center;

            button{
                border: none;
                font-size: clamp(1rem, 2.4vw, 1.3rem);
                padding: 1rem 2rem;
                border-radius: 30px;
                margin-top: 1.4rem;
                background-color: #fdcb4a;
                
                a{
                    color: black;
                }

                &:hover{
                    background-color: black;
                }
                &:hover a{
                    color: white;
                }
                
            }

            @media (max-width:500px) {
                flex-direction: column;
            }

            img{
                width: 200px;
                height: 200px;
                object-fit: cover;
            }
            p{
                width:70%;
                font-size: clamp(1rem, 5vw, 1.5rem);

                @media (max-width:500px) {
                    width: 100%;
                    padding-top: 2rem;
                }

            }
        }
    }
`

const Laws = styled.div`

    padding-top: 8rem;

    @media (max-width:500px) {
        padding-top: 4rem;
    }

    h2{
        font-size: clamp(1.5rem, 3vw, 3rem);
        border-bottom: 1px solid  #252525b3;
        padding-bottom: 2rem;
    }

    .law-list{
        margin-top: 3rem;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2rem;

        @media (max-width:500px) {
            grid-template-columns: repeat(1,1fr);
        }
        p{
            /* padding: 1rem; */
        }
    }
`

const Hn2 = styled.div`

padding-top: 8rem;

    @media (max-width:500px) {
        padding-top: 4rem;
    }

    .title{
        h1{
            font-size: clamp(1.5rem, 7vw, 3rem);
        }

        p{
            font-size: clamp(1rem, 2vw, 1.5rem);
            padding-top: 0.5rem;
            width: 80%;
            padding-top: 1rem;

            @media (max-width:500px) {
                width: 100%;
            }
        }

        
    }

    .flags-con{

        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 3rem;
        padding-top: 5rem;

        @media (max-width:500px) {
            grid-template-columns: repeat(2,1fr);
            gap: 1rem;
        }

        section{
            display: flex;
            align-items: center;
            /* justify-content: center; */

            @media (max-width:500px) {
                padding-bottom: 2rem;
            }
            
            div{
                img{
                    width: 50px;
                    margin-right: 1rem;
                    
                    @media (max-width:500px) {
                        width: 30px;
                    }
                }
            }

            .numbers-section{
                p{
                    font-size: clamp(1rem, 2vw, 1rem);
                }
                h2{
                    font-size: clamp(.6rem, 3vw, 1.5rem);
                }
            }
        }
    }
    
`

const Hn1 = styled.div`

    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 200px repeat(1,1fr);

    padding-top: 3rem;

    @media (max-width:500px) {
        display: flex;
        flex-direction: column;
    }

    .title{
        padding-bottom: -5rem;
        width: 90%;
        @media (max-width:500px) {
            width: 100%;
        }
        h1{
            font-size: clamp(1.5rem, 7vw, 3rem);
        }

        p{
            font-size: clamp(1.2rem, 1vw, 1.5rem);
            padding-top: 0.5rem;
        }
    }

    .nums{
        grid-row: 2/3;
        grid-column: 2/3;
        padding-left: 4rem;

        div{
            border-bottom: 1px solid  #2525252c;
        }

        @media (max-width:500px) {
            padding-left: 0;
            padding-top: 3rem;
        }

        div{

            display: flex;
            padding: 1.5rem 0;
            padding-bottom: 2rem;

            p{
                font-size: clamp(1rem, 2vw, 1.3rem);

            }

            h2{
                padding-top: .2rem;
                font-size: clamp(1.5rem, 5vw, 2rem);
                color: #fdcb4a;
            }

            span{
                padding-right: 5rem;
                font-size: clamp(1rem, 1vw, 1rem);
                opacity: 0.5;

                @media (max-width:500px) {
                    padding-right: 2rem;
                }
            }
        }
    }
    
`