import React from 'react'
import styled from 'styled-components'
import HumanTrafficking2 from './HumanTrafficking2'

const HumanTrafficking = () => {
  return (

    <Container>

        <Title>
        
            <h1>HumanTrafficking</h1>
            <p className='title-sub'>
                Human Trafficking is the fastest growing 
                crime and the second largest illegal trade 
                in the world. Article 23 of the Constitution 
                of India prohibits the trafficking of human 
                beings and forced labour. Various other Indian 
                laws and India’s ratification of several 
                international laws also clarifies India’s 
                stand on trafficking.
            </p>
        </Title>

        <TextWrapper>
            <h1 className='rolling-text' >HumanTrafficking</h1>
            <h1 className='rolling-text' >HumanTrafficking</h1>
            <h1 className='rolling-text' >HumanTrafficking</h1>
            <h1 className='rolling-text' >HumanTrafficking</h1>
            <h1 className='rolling-text' >HumanTrafficking</h1>
            <h1 className='rolling-text' >HumanTrafficking</h1>
        </TextWrapper>

        <Cards>

            <Card>
                
                <div className='imgContainer' >
                    <img className='image imgOne' src="./images/main/8.png" alt="" />
                </div>
                {/* <p className='content'>
                    Bonded Labour is an oppressive form 
                    of forced labour where, due to a debt 
                    or other obligation (customary, caste-based, 
                    economic consideration), the labourer forfeits 
                    certain basic rights and freedoms that are 
                    guaranteed by the Indian Constitution. 
                    Physical violence, verbal insults, brute 
                    force and sexual abuse are often common 
                    elements in bonded labour making it a 
                    serious human rights crime.
                </p> */}
            </Card>

        </Cards>

        <Diffinition className='padding'>
            <h1>
                Definition of <br /> Human Trafficking
            </h1>
            <div className='diff-layout'>
                <p><b>In March 2013, India passed the Criminal Law (Amendment) Act of 2013. This Act amended Section 370 of the Indian Penal Code and includes India’s first definition of human trafficking.</b></p>
                <p>According to this definition, human trafficking occurs when one person (the trafficker) uses force, fraud or coercion to induce, recruit, harbour or transport another person (the victim) for exploitation for his or her commercial gain.</p>
                <p className='three-para'>It also defines exploitation as “any act of physical exploitation or any form of sexual exploitation, slavery or practices similar to slavery, servitude, or the forced removal of organs.” The Act also clarifies the types of offences that are criminalized as trafficking violations and institutes heightened sentences for perpetrators.</p>
            </div>
        </Diffinition>

        <Forms className='padding'>
            <h1>Forms of Human Trafficking</h1>
            <div className='forms-container'>
                <div>
                    <img src="./images/forms/1.png" alt="" />
                    <p>Forced Labour</p>
                </div>
                <div>
                    <img src="./images/forms/2.png" alt="" />
                    <p>Bonded Labour</p>
                </div>
                <div>
                    <img src="./images/forms/3.png" alt="" />
                    <p>Commercial Sexual <br /> Exploitation</p>
                </div>
                <div>
                    <img src="./images/forms/4.png" alt="" />
                    <p>Child Marriage</p>
                </div>
                <div>
                    <img src="./images/forms/5.png" alt="" />
                    <p>Child Labour</p>
                </div>
                <div>
                    <img src="./images/forms/6.png" alt="" />
                    <p>Forced Beggary</p>
                </div>
                <div>
                    <img src="./images/forms/7.png" alt="" />
                    <p>Organ Trafficking</p>
                </div>
            </div>
        </Forms>

        <Current className='padding'>
                <h1>Current-trending forms of Human Trafficking</h1>
                
                <div className="current-container">
                    <div>
                        <img className='img-one' src="./images/main/10.png" alt="" />
                        <p><b>OSEC</b> (Online sexual exploitation of children)</p>
                    </div>
                    <div>
                        <img src="./images/main/11.png" alt="" />
                        <p>Forced Labour in scamming compounds</p>
                    </div>
                </div>
        </Current>

        <Numbers className='padding'>

            <h1>Human Trafficking by Numbers</h1>
            
            <div className='num-con'>
                <p><b>49.6 million</b>  people were living in modern slavery in 2021, of which <b>27.6 million</b> were in forced labour (Source ILO)</p>
                <p>Human trafficking generates an average of <b>150 billion dollars</b> per year.</p>
                <p>Of the <b>27.6 million</b> people in forced labour, <b>17.3 million</b> are exploited in the private sector; <b>6.3 million</b> in forced commercial sexual exploitation, and <b>3.9 million</b> in forced labour imposed by the state. (Source ILO)</p>
                <p>Women and girls account for <b>4.9 million</b> of those in forced commercial sexual exploitation and <b>6 million</b> of those in forced labour in other economic sectors. (Source ILO)</p>
                <p><b>12% of all those in forced labour are children</b>. More than half of these children are in commercial sexual exploitation. (Source ILO)</p>
                <p><b>Asia and the Pacific region</b> have the highest number of people in forced labour (15.1 million) (Source ILO)</p>
            </div>

        </Numbers>

        <HumanTrafficking2 />

    </Container>
    
    
  )
}

export default HumanTrafficking

const Container = styled.div`
    
    margin-top: 9rem;
    margin-bottom: 9rem;

    @media (max-width:500px) {
        margin-bottom: 0rem;
    }

    p{
        font-size: clamp(1.2rem, 2vw, 1.5rem);
    }

    h1{
        font-size: clamp(2.5rem, 7vw, 8rem); 
    }

    .padding{
        padding: 4rem;

            @media (max-width:500px) {
                padding: 1.5rem;
            }
    }
`

const Numbers = styled.div`
    h1{
        display: flex;
        justify-content: center;
        font-size: clamp(1.5rem, 7vw, 3rem);
    }

    .num-con{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
        margin: 0 3rem;
        margin-top: 5rem;

        @media (max-width: 900px) {
            margin: 0;
            margin-top: 4rem;
            display: flex;
            flex-direction: column;
        }

        p{
            padding: 2rem;
            background-color: #202020;
            border-radius: 20px;                  
            color: white;

            b{
                color: #FDCB4A;
            }
        }
    }
`

const Current = styled.div`

margin-top: 4rem;

    h1{
        display: flex;
        justify-content: center;
        font-size: clamp(1.5rem, 7vw, 3rem); 

    }

    


    .current-container{

        display: flex;
        justify-content: space-between;
        align-items: end;
        padding: 0 11rem;
        margin-top: 3rem;

        @media (max-width:500px) {
            flex-direction: column;
            padding: 0;
        }

        div{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        img{
            width: 70%;
            padding: 2rem 0;
        }
    }
    
`

const Forms = styled.div`

    h1{
        display: flex;
        justify-content: center;
         font-size: clamp(1.5rem, 7vw, 3rem);
    }
    .forms-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
        padding-top: 4rem;

        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 250px;

                @media (max-width:500px) {
                    /* width: 100px; */
                }
            }
            p{
                padding-top: 0.7rem;
                font-family: skb;
                text-align: center;
            }
        }
    }
`



const Diffinition = styled.div`
    



    h1{
        font-size: clamp(2.5rem, 7vw, 7rem); 
        line-height: 90px;

        @media (max-width:500px) {
            line-height: 40px;
        }
        @media (max-width:320px) {
            font-size: clamp(2rem, 7vw, 7rem); 
            line-height: 30px;
        }
    }

    .diff-layout{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        padding-top: 8rem;

        @media (max-width:500px) {
            display: flex;
            flex-direction: column;
            padding-top: 3rem;
            gap: 30px;
        }

        b{
            font-size: clamp(1.2rem, 6vw, 1.7rem);

            @media (max-width:500px) {
                font-size: clamp(1.2rem, 4vw, 1.7rem);
            }
        }

        .three-para{
            grid-column:2/3;
        }
    }
`

const TextWrapper = styled.div`

    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 6rem 0;

    .rolling-text{
        
        font-size: clamp(3rem, 3vw, 5rem);
        color: #FDCB4A;
        /* -webkit-text-stroke-width: 0.01px;
        -webkit-text-stroke-color: #FDCB4A; */
        white-space: nowrap;
        padding: .5rem 3rem;
        /* letter-spacing: 10px; */
        animation: move-rtl 6000ms linear infinite;
        /* opacity: 0.5; */
        /* background-color: #000; */
        
    }

    @keyframes move-rtl {
        0%{
            transform: translateX(0);
        }

        100%{
            transform: translateX(-100%);
        }
    }
`



const Title = styled.div`

    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column; 
    gap: 30px;
    padding: 0 2rem;
    

    .title-sub{
        
        border-bottom: 1px #000 solid;
        padding-bottom: 2rem;
        /* text-align: center; */
    }
`

const Cards = styled.div`
    max-width: 1300px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 4rem;

    padding: 0 2rem;
`

const Card = styled.div`
    /* height: 80vh; */
    

    h2{
        font-size: clamp(2rem, 4vw, 8rem);
        padding-bottom: 1rem;
    }

    .sub{
        font-family: skb;
        opacity: 0.5;
        letter-spacing: 5px;
        padding-bottom: 4rem;
    }

    .content{
        font-size: clamp(1.2rem, 2vw, 1.5rem);
        /* border-bottom: 1px #000 solid; */
        padding-bottom: 2rem;
        /* text-align: center; */
        padding: 4rem 0;
    }

    .imgContainer{
        /* width: 100%;
        height: 600px; */
        display: flex;
        justify-content: center;

        .imgOne{
            position: relative;
            left: -20px;
        }
        img{
            width: 800px;
            object-fit: cover;
            border-radius: 9px;

            @media (max-width:900px) {
                width:100%;
            }
        }
    }
`