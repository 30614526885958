import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const RegistrationDetails = () => {
  return (
    <Main>


      <Title>
          <h1>Registration Details</h1>
      </Title>

      <Container>


        <div className=" box box1">
          <h2>80G CERTIFICATE</h2>
          <a target='blank' href="./pdf/80G CERTIFICATE - CGAF.pdf">VIEW</a>
        </div>
        
        <div className=" box box2">
          <h2>PAN CARD</h2>
          <a target='blank' href="./pdf/CGAF - PAN CARD (1).pdf" >VIEW</a>
        </div>

        <div className=" box box3">
          <h2>12AA CERTIFICATE</h2>
          <a target='blank' href="./pdf/12AA CERTIFICATE.pdf">VIEW</a>
        </div>

        <div className=" box box4">
          <h2>INCORPORATION CERTIFICATE</h2>
          <a target='blank' href="./pdf/CERTIFICATE OF INCORPORATION (1).PDF">VIEW</a>
        </div>
       

      </Container>

      <NGO>
          <h2>NGO DARPAN</h2>
          <p>Unique Id of VO/NGO: TN/2022/0332737</p>
      </NGO>

    </Main>
  )
}

export default RegistrationDetails

const NGO = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 5rem;
`

const Main = styled.div`
  margin: 6rem 0;
  margin-top: 9rem;

  display: flex;
  flex-direction: column;
  align-items: center;

`

const Title = styled.div`
  h1{
    /* margin: 3rem; */
    margin-bottom: 4rem;

    font-size: clamp(2rem, 6vw, 5rem); 
    /* text-align: center; */

  }
`

const Container = styled.div`

  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(2,1fr);
  grid-gap: .6rem;

  height: 50vh;
  width: 50%;

  margin: 0 auto;

  @media (max-width:980px) {
    width :80%;
  }

  @media (max-width:574px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width:574px) {
    height: 100vh;
  }


.box1{
  grid-row: 1/3;
}
.box2{
}
.box3{
}
.box4{
  grid-column: 2/4;
}

.box{
  background-color: #1E1E1E;
  border-radius: 9px;

  transition: 0.3s all ease-in-out;

  @media (max-width:574px) {
    height: 500px;
  }

  

  &:hover{
    background-color: #fdcb4f;
    
  }

  &:hover h2{
    color: #1e1e1e;
  }

  &:hover a{
    
    background-color: #1E1E1E;
    color: white;
    
  }


  h2{
    color: white;
    padding: 1rem;
    padding-bottom: 2rem;

  }
  a{
    color: white;
    margin: 1rem;
    padding: 0.5rem 2rem;
    border-radius: 10px;
    background-color: #fdcb4f;
    color: #1e1e1e;
    font-family: skb;
  }
}
  
`